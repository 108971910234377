import React, { useContext, useState } from "react";
import "./resetPassword.css";
import "../sections/login.css";
import { useNavigate, useParams } from "react-router-dom";
import { axios_ } from "../axios";
import { useRef } from "react";
import { Context } from "../store";

import Header from "../sections/Header";

const ResetPassword = () => {
    const [updating, setUpdating] = useState(false);
    const [password, setPassword] = useState("");
    const [secretCode, setSecretCode] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const formRef = useRef();
    const [isAuthenticated] = useState(false);
    const { showMessage } = useContext(Context);
    const navigate = useNavigate();
    const { id } = useParams();

    async function handleUpdate(e, password, confirmPassword) {
        try {
            e.preventDefault();
            setUpdating(true);
            const response = await axios_.post("/update", { password, confirmPassword, secretCode, token: id });
            if (response.data.success) {
                showMessage("Password updated successfully .Please login", true);
                navigate("/");
            } else {
                showMessage(response.data.message);
                setUpdating(false);
            }
        } catch (e) {
            setUpdating(false);
            console.log("Error while updating password", e);
        }
    }

    const handleEmail = (e) => {
        e.preventDefault();
        setPassword(e.target.value);
    };

    function handlePassword(e) {
        e.preventDefault();
        setConfirmPassword(e.target.value);
    }

    return (
        <>
            <div className="container" style={{ overflow: "hidden", overflowY: "hidden" }}>
                <Header
                    isAuthenticated={isAuthenticated}
                    onSaveDraft={() => {
                        formRef.current.onSubmit(true);
                    }}
                />

                <div className="form-column" style={{ display: "flex", justifyContent: "center", alignContent: "center", width: "100vw", height: "70vh" }}>
                    <div className="login-form">
                        <p style={{ fontSize: "12px", color: "red", fontWeight: "bold", textAlign: "center" }}>{message}</p>

                        <h2 className="head">Enter new password</h2>
                        <p className="head" style={{ color: "red", fontWeight: "bold", fontSize: "14px" }}></p>
                        <p style={{ textAlign: "center", fontSize: "12px" }}>Secret code is sent by email.</p>
                        <form>
                            <input className="form-input" type="password" placeholder="Enter New Password" value={password} onChange={handleEmail} />
                            <input className="form-input" type="password" placeholder="Confirm New  Password" value={confirmPassword} onChange={handlePassword} />
                            <input className="form-input" type="input" placeholder="Secret Code" value={secretCode} onChange={(e) => setSecretCode(e.target.value)} />

                            <div className="login-button">
                                <button
                                    style={{ cursor: updating ? "not-allowed" : "pointer" }}
                                    className="submit-button"
                                    onClick={(e) => handleUpdate(e, password, confirmPassword)}
                                    disabled={updating}>
                                    {updating ? "Updating..." : "Update"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
