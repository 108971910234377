import { useContext, useRef } from "react";
import "../App.css";
import "./form.css";
import MultiStepForm from "../components/MultiStepForm";
import Header from "../sections/Header";
import Footer from "../sections/Footer";
import Login from "../sections/Login";
import { useState, useEffect } from "react";
import { axios_ } from "../axios";
import { Context } from "../store";

const Home = () => {
    const formRef = useRef();
    const { isAuthenticated, setAuthenticated } = useContext(Context);
    const { onSubmit } = useContext(Context);
    const [isLoginIn, setIsLogingIn] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token) {
            checkAuthentication(token);
        } else {
            setLoading(false);
        }
    }, []);

    async function checkAuthentication(token) {
        try {
            let res = await axios_.post("/checkAuthentication", { token });
            if (res.status === 200) {
                setAuthenticated(true);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }
    const handleLogin = async (email, password, setError) => {
        try {
            const data = { UserName: email, Password: password };
            setIsLogingIn(true);
            let response = await axios_.post("/login", data, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const token = response.data.token;
            localStorage.setItem("token", token);
            setError("");
            setAuthenticated(true);
            setIsLogingIn(false);
            localStorage.setItem("isAuthenticated", "true");
        } catch (e) {
            setLoading(false);
            setIsLogingIn(false);
            setError(e?.response?.data.message || "Error in login, please try again later");
        }
    };

    if (loading) {
        return (
            <div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <span class="loader"></span>
            </div>
        );
    }
    return (
        <div style={{ position: "relative" }}>
            <Header isAuthenticated={isAuthenticated} onSaveDraft={onSubmit} />
            {isAuthenticated ? <MultiStepForm ref={formRef} /> : <Login onLogin={handleLogin} isLoginIn={isLoginIn} />}
            <Footer />
        </div>
    );
};

export default Home;
