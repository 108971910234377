import axios from "axios";

export const axios_ = axios.create({
    baseURL: "https://elliswebapi.infodatixhosting.com/",
    // baseURL: "http://localhost:3021",
});

axios_.interceptors.request.use((request) => {
    let token = localStorage.getItem("token");
    request.headers.Authorization = `Bearer ${token}`;
    return request;
});
