import React, { useEffect, useState } from "react";
import "./school.css";
import { useMediaQuery } from "react-responsive";

const School = ({ onNext, onPrev, updateFormData, formDataMain, showModel, setShowModel }) => {
  const [formData, setFormData] = useState({
    fam: "",
    fathername: "",
    fatheremp: "",
    fatherphone: "",
    fatheremail: "",
    mothername: "",
    motheremp: "",
    motherphone: "",
    motheremail: "",
    legal: "",
    nofam: "",
    nochild: "",
    childage: "",
    childcol: "",
    anotherfam: "",
    gen: "",
  });
  const [isMothersMail, setIsMothersmail] = useState(true);
  const [isFathersMail, setIsFathersMmail] = useState(true);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    setFormData(formDataMain);
  }, []);

  const handleChange = (field, value) => {
    const newFormData = { ...formData, [field]: value };
    setFormData(newFormData);
    updateFormData(newFormData);
  };

  function validateMail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  useEffect(() => {
    setIsMothersmail(validateMail(formData.motheremail));
  }, [formData.motheremail]);

  useEffect(() => {
    setIsFathersMmail(validateMail(formData.fatheremail));
  }, [formData.fatheremail]);

  return (
    <div style={{ color: "#1a3c51", fontSize: isMobile ? "medium" : "smaller" }}>
      <div>
        <p className="divider">
          <hr className="hr " />
        </p>
      </div>
      <div
        className="form-container"
        style={{
          marginLeft: isMobile ? "1rem" : "28.5rem",
          width: isMobile ? "auto" : "900px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            marginLeft: isMobile ? "0rem" : "-13.5rem",
            marginTop: "0.75rem",
            width: isMobile ? "auto" : "60rem",
          }}
        >
          {" "}
          <div className="col1" style={{ marginLeft: "0rem" }}>
            <div>
              <h2>First Generation College Student?</h2>
              <select style={{ width: isMobile ? "" : "31.7rem", height: "2.5rem" }} value={formData.fam} onChange={(e) => handleChange("fam", e.target.value)} tabIndex={1}>
                <option value="Select One">Select One</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div>
              <h2>Father's Name</h2>
              <input type="text" value={formData.fathername} onChange={(e) => handleChange("fathername", e.target.value)} tabIndex={3} />
            </div>

            <div>
              <h2>Father's Phone</h2>
              <input type="text" value={formData.fatherphone} onChange={(e) => handleChange("fatherphone", e.target.value)} tabIndex={5} />
            </div>

            <div>
              <h2>Mother's Name</h2>
              <input type="text" value={formData.mothername} onChange={(e) => handleChange("mothername", e.target.value)} tabIndex={7} />
            </div>

            <div>
              <h2>Mother's Phone</h2>
              <input type="text" value={formData.motherphone} onChange={(e) => handleChange("motherphone", e.target.value)} tabIndex={9} />
            </div>
            <div>
              <h2>Legal Guardian (If Different from Above)</h2>
              <input type="text" value={formData.legal} onChange={(e) => handleChange("legal", e.target.value)} tabIndex={11} />
            </div>

            <div>
              <h2>Number of Children</h2>
              <input type="number" value={formData.nochild} onChange={(e) => handleChange("nochild", e.target.value)} tabIndex={13} />
            </div>

            <div>
              <h2>Number Attending College</h2>
              <input type="number" value={formData.childcol} onChange={(e) => handleChange("childcol", e.target.value)} tabIndex={15} />
            </div>
          </div>
          <div className="col2-school" style={{ marginLeft: isMobile ? "0" : "5rem" }}>
            {" "}
            <div>
              <h2>Single Parent Family?</h2>
              <select style={{ width: "31.5rem" }} value={formData.gen} onChange={(e) => handleChange("gen", e.target.value)} tabIndex={2}>
                <option value="Select One">Select One</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div>
              <h2>Father's Employer</h2>
              <input type="text" value={formData.fatheremp} onChange={(e) => handleChange("fatheremp", e.target.value)} tabIndex={4} />
            </div>
            <div>
              <h2>Father's Email</h2>
              <input type="email" value={formData.fatheremail} onChange={(e) => handleChange("fatheremail", e.target.value)} tabIndex={6} />
              <span style={{ color: "red", fontWeight: "bold" }}>{isFathersMail ? "" : <p>Please Enter Valid Mail</p>}</span>
            </div>
            <div>
              <h2>Mother's Employer</h2>
              <input type="text" value={formData.motheremp} onChange={(e) => handleChange("motheremp", e.target.value)} tabIndex={8} />
            </div>
            <div>
              <h2>Mother's Email</h2>
              <input type="email" value={formData.motheremail} onChange={(e) => handleChange("motheremail", e.target.value)} tabIndex={10} />
              <span style={{ color: "red", fontWeight: "bold" }}>{isMothersMail ? "" : <p>Please Enter Valid Mail</p>}</span>
            </div>
            <div>
              <h2>Total Number of Family Members Living at Home</h2>
              <input type="number" value={formData.nofam} onChange={(e) => handleChange("nofam", e.target.value)} tabIndex={12} />
            </div>
            <div>
              <h2>Children's Ages</h2>
              <input type="text" value={formData.childage} onChange={(e) => handleChange("childage", e.target.value)} tabIndex={14} />
            </div>
            <div>
              <h2>Has Another Family Member Received this Scholarship?</h2>
              <select style={{ width: "31.7rem" }} value={formData.anotherfam} onChange={(e) => handleChange("anotherfam", e.target.value)} tabIndex={16}>
                <option value="Select One">Select One</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className="divider">
          <hr className="hr " />
        </p>
        <button className="button-form" onClick={onPrev}>
          PREVIOUS
        </button>
        <button className="button-form2" onClick={(event) => onNext(event)}>
          NEXT
        </button>
      </div>
    </div>
  );
};

export default School;
