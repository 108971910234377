import React, { useContext } from "react";
import "./h.css";
import Button from "./../components/Button";
import { Context } from "../store";
import { useNavigate } from "react-router-dom";

export default function Header({ onSaveDraft }) {
    const { isAuthenticated, setAuthenticated } = useContext(Context);
    const navivation = useNavigate();

    function handleLogout() {
        localStorage.clear();
        setAuthenticated(false);
        navivation("/");
    }
    return (
        <header style={{ boxShadow: "0px 1px 6px 1px #dae3e8", position: "sticky", top: "0px", backgroundColor: "#fff" }}>
            <div className="lower-nav">
                <div className="logo-container">
                    <img
                        className="logo"
                        loading="lazy"
                        decoding="async"
                        src="https://www.theellisfoundation.org/wp-content/uploads/2019/05/The-Ellis-Foundation-2020.png"
                        alt="The-Ellis-Foundation-2020"
                    />
                </div>
                <div className="nav-items" style={{ color: "#075b85" }}>
                    <h2>SCHOLARSHIP APPLICATION</h2>
                    <span style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        {isAuthenticated && (
                            <span>
                                <Button title="Save As Draft" onClick={onSaveDraft} />
                            </span>
                        )}
                        <span>{isAuthenticated && <Button title="Logout" onClick={handleLogout} />}</span>
                    </span>
                </div>
            </div>
        </header>
    );
}
