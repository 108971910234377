import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import ResetPassword from "./components/ResetPassword";
import PopUp from "./components/PopUp";
function App() {
  return (
    <div>
      <PopUp />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/reset_pass" element={<ResetPassword />} />
      </Routes>
    </div>
  );
}

export default App;
