import React, { useContext } from "react";
import { Context } from "../store";

const PopUp = () => {
    const { message, success } = useContext(Context);
    return (
        message !== undefined && (
            <div className={`popup-content ${success ? "success" : "fail"}`}>
                <p>{message} </p>
                <div style={{ backgroundColor: success ? "green" : "red" }} className="bar"></div>
            </div>
        )
    );
};

export default PopUp;
