import React from "react";
import "./form.css";

const initialText =
    'By selecting "I Agree", I acknowledge that I fully understand that my participation in The Ellis Foundation Mentoring Program is a requirement of receiving The Ellis Foundation Scholarship. If selected for a scholarship, I understand I will be assigned a mentor my freshman year and agree to participate fully in maintaining regular communication with my mentor. I understand that I may be asked to serve as a mentor myself in subsequent years and agree to serve if able. I agree to do my best to participate in all mentoring activities that may become available to me. I agree to attend a mentoring meeting each semester and to write a thank you letter to my scholarship sponsor each semester. I understand the importance of being responsive to the Ellis staff and agree to promptly inform them of any changes in my personal contact information or college plans. I understand and agree that my participation in The Ellis Foundation Mentoring Program will be approximately five (5) hours per semester.';

const Agreement = ({ onNext, updateFormData, isChecked, setIsChecked }) => {
    const handleCheckboxChange = () => {
        setIsChecked((prevIsChecked) => {
            updateFormData({ agreement: !prevIsChecked });
            return !prevIsChecked;
        });
    };

    const isNextButtonDisabled = !isChecked;

    return (
        <div className="agreem" style={{ fontSize: "small" }}>
            <p className="divider">
                <hr className="hr " />
            </p>
            <div className="para1">
                <h2>Please submit application in one sitting.</h2>
                <p>
                    This application must be completed all at once. There are no letters of recommendation required.
                    <br />
                    You will be required to upload one photo. The application also requires a short essay at the end.
                </p>
            </div>
            <p className="divider">
                <hr className="hr " />
            </p>
            <div className="para">
                <h2>
                    Agreement<span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </h2>
            </div>
            <div className="required-check">
                <label style={{ display: "flex", flexDirection: "row" }} className="agree">
                    <input type="checkbox" className="check1" name="agreement" checked={isChecked} onChange={handleCheckboxChange} />
                    <p>
                        <b>I Agree to the terms</b>
                    </p>
                </label>
            </div>

            <div className="tc">
                <textarea
                    style={{
                        width: "80%",
                        fontWeight: "bold",
                        fontSize: "xx-smaller",
                        height: "90px",
                        color: "#383533",
                        resize: "none",
                        border: "1px solid #ccc",
                        padding: "8px",
                        fontFamily: "Montserrat,sans-serif",
                    }}
                    defaultValue={initialText}
                    readOnly
                />
            </div>
            <p className="divider">
                <hr className="hr " />
            </p>

            <button className="button-form" onClick={(event) => onNext(event)} disabled={isNextButtonDisabled}>
                NEXT
            </button>
        </div>
    );
};

export default Agreement;
