import React, { useState } from "react";
function Button({ title, onClick = undefined }) {
    const [saving, setSaving] = useState(false);

    async function handleclick() {
        try {
            console.log("clicked");
            setSaving(true);
            await onClick();
            setSaving(false);
        } catch (e) {
            console.log(e);
            setSaving(false);
        }
    }

    return (
        <button disabled={saving || !onClick ? true : false} className={`button ${saving ? "loading" : ""}`} onClick={handleclick}>
            {title}
        </button>
    );
}

export default Button;
