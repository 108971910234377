import React from 'react';
import './footer.css';
import { useMediaQuery } from 'react-responsive';

const Footer = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <footer >
          <div className="upper" style={{ flexDirection: isTabletOrMobile ? 'column' : 'row', height: isTabletOrMobile ? 'auto' : '3.8rem' }}>
        <div className="social-icon" style={{ marginLeft: isTabletOrMobile ? '0' : '13.2rem', justifyContent: isTabletOrMobile ? 'center' : 'flex-start' }}>
          <a href="https://www.facebook.com/TheEllisFoundation/">
            <img className="fb-icon" src="./images/fb.png" alt="" />
            </a>
          <a href="https://www.linkedin.com/company/theellisfoundation/about/"><img className="ln-icon" src="./images/linkin.png" alt="" /></a>
          <img className="call-icon" src="./images/call.png" alt="" />

          <h3 className="num">620.223.2232</h3>
        </div>

        <div className="two-buttons" style={{ justifyContent: isTabletOrMobile ? 'center' : 'flex-end', marginTop: isTabletOrMobile ? '10px' : '0' }}>
        <button className="buttonfc1">
          <a style={{textDecoration:'none', color:'white'}} href="https://secure.givelively.org/donate/the-ellis-foundation">
            <b>DONATE NOW</b>
            </a></button>
        <button className="buttonfc2">
          <a  style={{textDecoration:'none', color:'white'}} href="https://www.theellisfoundation.org/contact-us/">
            <b>CONTACT US</b>
            </a></button>
        </div>
      </div>
    
      

      <div className='bl' >
      <div className="ff"> 

      <small>© 2024 The Ellis Foundation. All Rights Reserved. Privacy Policy</small>
               <small> Web Design In Kansas City by KC Web Specialists</small>
</div>

</div>



      
    </footer>
  );
};

export default Footer;
